<template>
  <div class="mb-8 my-16 py-6 px-4">
    <v-row>
      <v-col class="col-12">
        <h1>Gobierno Central</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <h4>{{ info.ccNombre }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <InitiativesComponent/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Initiatives from '@/components/Initiatives/Initiatives'
import {mapGetters} from "vuex";
export default {
  name: "CentralGoberment",
  components: {
    'InitiativesComponent': Initiatives,
  },
  computed: {
    ...mapGetters({
      info: ['authentication/info'],
    }),
  },
  created() {
    if(this.info.tipoUnidad === "Académica"){
      this.$router.push("/unidades-academicas");
    }
  }
}
</script>
<style scoped>
</style>