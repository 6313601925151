<template>
  <div class="mb-8 my-1 py-1 px-4">
    <v-tabs v-model="tabs">
      <v-tab>Iniciativas</v-tab>
      <v-tab>Reporte de avances</v-tab>
      <v-tab>Iniciativas Finalizadas</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <InitiativesMaintainerComponent/>
      </v-tab-item>
      <v-tab-item>
        <InitiativesProgressReportComponent/>
      </v-tab-item>
      <v-tab-item>
        <InitiativesFinishedComponent/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import InitiativesMaintainer from '@/components/Initiatives/InitiativesMaintainer'
import InitiativesProgressReport from '@/components/Initiatives/InitiativesProgressReport'
import InitiativesFinished from '@/components/Initiatives/InitiativesFinished'

export default {
  name: "Initiatives",
  components: {
    'InitiativesMaintainerComponent': InitiativesMaintainer,
  'InitiativesProgressReportComponent': InitiativesProgressReport,
    'InitiativesFinishedComponent': InitiativesFinished
  },
  data() {
    return {
      tabs: null,
    };
  },
}
</script>

<style scoped>

</style>