<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col class="col-12 text-left"></v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="col-4">
          <v-select
              placeholder="Plan Transversal"
              :items="planesTransversalesFilter"
              :item-text="'name'"
              :item-value="'id'"
              icon="mdi-arrow-bottom-right-thin-circle-outline"
              label="Plan Transversal"
              @change = "id => fieldSearch(id, 0)"
          />
        </v-col>
        <v-col class="col-4">
          <v-select
              placeholder="Eje Estratégicos"
              :items="ejesEstrategicosFilter"
              :item-text="'nombreEje'"
              :item-value="'id'"
              icon="mdi-arrow-bottom-right-thin-circle-outline"
              label="Eje Estratégico"
              @change = "id => fieldSearch(id, 1)"
          />
        </v-col>
        <v-col class="col-4">
          <v-select
              placeholder="Area Misional"
              :items="areasMisionalesFilter"
              :item-text="'nombreArea'"
              :item-value="'id'"
              icon="mdi-arrow-bottom-right-thin-circle-outline"
              label="Area Misional"
              @change = "id => fieldSearch(id, 2)"
          />
        </v-col>
      </v-row>
      <v-data-table
          dense
          :headers="this.headers"
          :items="this.rows"
          item-key="id"
          class="elevation-1"
          :search="search">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tabla con listado completo de Iniciativas.</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="800px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-right">
                  <v-btn v-bind="attrs" v-on="on" style="background-color: #EA7730;
                color: white" dark class="mr-md-1">Crear Iniciativa</v-btn>
                </div>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Crear Iniciativa</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            label="Plan Transversal"
                            placeholder="Plan Transversal"
                            :items="planesTransversales"
                            :item-text="'name'"
                            :item-value="'id'"
                            icon="mdi-arrow-bottom-right-thin-circle-outline"
                            v-model="iniciativa.planTransversal"
                            @change="item => getAllItemsEjeEstrategicosByTransversal(iniciativa.planTransversal)"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            label="Eje Estratégico"
                            placeholder="Eje Estratégico"
                            :items="ejesEstrategicos"
                            :item-text="'nombreEje'"
                            :item-value="'id'"
                            icon="mdi-arrow-bottom-right-thin-circle-outline"
                            v-model="iniciativa.ejeEstrategico"
                            @change="item => getAllItemsAreaMisionalByEje(iniciativa.ejeEstrategico)"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            label="Area Misional"
                            placeholder="Areas Misional"
                            :items="areasMisionales"
                            :item-text="'nombreArea'"
                            :item-value="'id'"
                            icon="mdi-arrow-bottom-right-thin-circle-outline"
                            v-model="iniciativa.areaMisional"
                            @change="item => getAllItemsEstrategiasByEjeAndTransversal(iniciativa.ejeEstrategico, iniciativa.areaMisional)"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            label="Estrategia"
                            placeholder="Estrategia"
                            :items="estrategias"
                            :item-text="'name'"
                            :item-value="'id'"
                            icon="mdi-arrow-bottom-right-thin-circle-outline"
                            v-model="iniciativa.estrategia"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="col-6">
                        <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="iniciativa.startDate"
                                label="Fecha de Inicio"
                                persistent-hint
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="iniciativa.startDate"
                              no-title
                              @input="menu1 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col class="col-6">
                        <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="iniciativa.endDate"
                                label="Fecha de Termino"
                                persistent-hint
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="iniciativa.endDate"
                              no-title
                              @input="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                            v-model="iniciativa.nombre"
                            filled
                            counter
                            rows="2"
                            label="Iniciativa"
                            color="primary-color"
                            no-resize
                            name="input-10-2"
                            maxlength="350"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                            v-model="iniciativa.descripcion"
                            filled
                            counter
                            rows="4"
                            label="Descripción"
                            color="primary-color"
                            no-resize
                            name="input-10-2"
                            maxlength="1500"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close()">Cerrar</v-btn>
                  <v-btn v-if="iniciativa.id === 0" color="blue darken-1" text @click="save()">Guardar</v-btn>
                  <v-btn v-else color="blue darken-1" text @click="update()">Actualizar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.estrategia="{item}">
          <div v-if="item.estrategia.length < 150 ">{{item.estrategia}}</div>
          <div v-else>{{ item.estrategia.substring(0, 146)+ " ..." }}</div>
        </template>
        <template v-slot:item.name="{item}">
          <div v-if="item.name.length < 150 ">{{item.name}}</div>
          <div v-else>{{ item.name.substring(0, 146)+ " ..." }}</div>
        </template>
        <template v-slot:item.description="{item}">
          <div v-if="item.description.length < 150 ">{{item.description}}</div>
          <div v-else>{{ item.description.substring(0, 146)+ " ..." }}</div>
        </template>
        <template v-slot:item.acciones="{item}">
          <v-btn small class="mr-2 primary" @click="edit(item)">
            <v-icon small class="mr-1">mdi-pencil</v-icon>Editar</v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import {mapActions} from 'vuex'
import Swal from "sweetalert2";
export default {
  name: "InitiativesMaintainer",
  data() {
      return {
        loading: false,
        dialog: false,
        planesTransversales: [],
        planesTransversalesFilter: [],
        ejesEstrategicos:[],
        ejesEstrategicosFilter:[],
        areasMisionales:[],
        areasMisionalesFilter:[],
        estrategias: [],
        rows: [],
        iniciativa: {
          id: 0,
          planTransversal: 0,
          ejeEstrategico: 0,
          areaMisional: 0,
          estrategia: 0,
          nombre: "",
          descripcion: "",
          startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        },
        defaultIniciativa: {
          id: 0,
          planTransversal: 0,
          ejeEstrategico: 0,
          areaMisional: 0,
          estrategia: 0,
          nombre: "",
          descripcion: "",
          startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        },
        headers: [
          { text: 'Plan Tranversal', value: 'planTransversal.name', align: 'start', sortable: true},
          { text: 'Eje Estrategico', value: 'estrategia.ejeEstrategico.nombreEje' },
          { text: 'Area Misional', value: 'estrategia.areaMisional.nombreArea' },
          { text: 'Estrategia', value: 'estrategia.name', width: '17%' },
          { text: 'Nombre', value: 'name', width: '17%' },
          { text: 'Descripción', value: 'description',width: '17%' },
          { text: 'Fecha Inicio', value: 'startDate'},
          { text: 'Fecha Termino', value: 'endDate'},
          { text: "Acciones", value: 'acciones'}
        ],
        menu1: false,
        menu2: false,
        search: ""
      }
  },
  methods: {
    ...mapActions('main', [
      'getAllPlanesTransversales',
      'getAllAreasMisionalesByEje',
      'getAllAreaMisional',
      'getAllEjeEstrategico',
      'getAllEjeEstrategicosByTransversal',
      'getAllEstrategiasByEjeAndTransversal',
      'createInitiative',
      'getAllNotFinishedInitiatives',
      'updateIniciativa'
    ]),
    fieldSearch(id, type){
      switch (type){
        case 0:
          this.fieldSearchPlanTransversal(id);
          break;
        case 1:
          this.fieldSearchEjeEstrategico(id);
          break;
        case 2:
          this.fieldSearchAreaMisional(id);
          break;
      }
    },

    fieldSearchPlanTransversal(id){
      if(id === 0) {
        this.search = "";
      } else {
        let res = this.planesTransversalesFilter.filter((element) => {
          return element.id === id
        });
        this.search = res[0].name;
      }
    },
    fieldSearchEjeEstrategico(id){
      console.log(id);
      console.log(this.ejesEstrategicosFilter);
      if(id === 0) {
        this.search = "";
      }else{
        let res = this.ejesEstrategicosFilter.filter((element) => {
          return element.id === id
        });
        this.search = res[0].nombreEje;
      }
    },
    fieldSearchAreaMisional(id){
      if(id === 0) {
        this.search = "";
      }
      else {
        let res = this.areasMisionalesFilter.filter((element) => {
          return element.id === id
        });
        this.search = res[0].nombreArea;
      }
    },

    parseDate (date) {
      if (!date) return null
      const [ day, month, year] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    save () {
      this.loading=true;
      this.createInitiative(this.iniciativa).then(async response => {
        await this.getAllItemsInitiatives();
        console.log(response.data.response);
        Swal.fire({
          title: 'Crear Iniciativa',
          text: 'Se ha creado la iniciativa ' + this.iniciativa.nombre,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        })
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Crear Iniciativa',
          text: e,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    async edit(item) {
      this.iniciativa.id = item.id;
      this.iniciativa.planTransversal = item.planTransversal.id;
      await this.getAllItemsEjeEstrategicosByTransversal(this.iniciativa.planTransversal);
      this.iniciativa.ejeEstrategico = item.estrategia.ejeEstrategico.id;
      await this.getAllItemsAreaMisionalByEje(this.iniciativa.ejeEstrategico);
      this.iniciativa.areaMisional = item.estrategia.areaMisional.id;
      await this.getAllItemsEstrategiasByEjeAndTransversal(this.iniciativa.ejeEstrategico, this.iniciativa.areaMisional)
      this.iniciativa.estrategia = item.estrategia.id;
      this.iniciativa.nombre = item.name;
      this.iniciativa.descripcion = item.description;
      this.iniciativa.startDate = this.parseDate(item.startDate);
      this.iniciativa.endDate = this.parseDate(item.endDate);
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.iniciativa = Object.assign({}, this.defaultIniciativa);
      })
    },
    update(){
      this.updateIniciativa(this.iniciativa).then(async response => {
        await this.getAllItemsInitiatives();
        console.log(response.data.response);
        Swal.fire({
          title: 'Modificar Iniciativa',
          text: 'Se ha modificado la iniciativa id: ' + this.iniciativa.nombre,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        });
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Modificar Iniciativa',
          text: e,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    getAllItemsPlanesTransversales() {
      this.getAllPlanesTransversales().then(response => {
        this.planesTransversales = response.data.response;
      }).catch(e => {
        console.log(e);
      });
    },
    getAllItemsPlanesTransversalesFilter() {
      this.getAllPlanesTransversales().then(response => {
        this.planesTransversalesFilter.push({id: 0, name: "Todos"});
        response.data.response.forEach((item) => {
          this.planesTransversalesFilter.push(item);
        });
      }).catch(e => {
        console.log(e);
      });
    },

    getAllItemsEjeEstrategicosFilter() {
      this.getAllEjeEstrategico().then(response => {
        this.ejesEstrategicosFilter.push({id: 0, nombreEje: "Todos"});
        response.data.response.forEach((item) => {
          this.ejesEstrategicosFilter.push(item);
        });
      }).catch(e => {
        console.log(e);
      });
    },

    getAllItemsAreasMisionalesFilter() {
      this.getAllAreaMisional().then(response => {
        this.areasMisionalesFilter.push({id: 0, nombreArea: "Todos"});
        response.data.response.forEach((item) => {
          this.areasMisionalesFilter.push(item);
        });
      }).catch(e => {
        console.log(e);
      });
    },

    getAllItemsEjeEstrategicosByTransversal(idTransversal) {
      this.getAllEjeEstrategicosByTransversal(idTransversal).then(response => {
        this.ejesEstrategicos = response.data.response;
      }).catch(e => {
        console.log(e);
      });
    },
    getAllItemsAreaMisionalByEje(idEje){
      this.getAllAreasMisionalesByEje(idEje).then(response => {
        this.areasMisionales = response.data.response;
        }).catch(e => {
        console.log(e);
      });
    },
    getAllItemsEstrategiasByEjeAndTransversal(eje, misional){
      this.getAllEstrategiasByEjeAndTransversal({"eje": eje, "misional": misional}).then(response => {
        this.estrategias = response.data.response;
      }).catch(e => {
        console.log(e);
      });
    },
    async getAllItemsInitiatives(){
      this.getAllNotFinishedInitiatives().then(response => {
        this.rows = response.data.response;
      }).catch(e => {
        console.log(e);
      });
    }
  },
  async created() {
    await this.getAllItemsPlanesTransversales();
    await this.getAllItemsPlanesTransversalesFilter();
    await this.getAllItemsEjeEstrategicosFilter();
    await this.getAllItemsAreasMisionalesFilter();
    await this.getAllItemsInitiatives();
  },
  computed: {
    // computedStartDateFormatted () {
    //   return this.formatDate(this.startDate)
    // },
    // computedEndDateFormatted () {
    //   return this.formatDate(this.endDate)
    // },
  },
  watch: {
    // startDate () {
    //   this.startDateFormatted = this.formatDate(this.startDate)
    // },
    // endDate () {
    //   this.endDateFormatted = this.formatDate(this.endDate)
    // },
  },
}
</script>
<style scoped>

</style>