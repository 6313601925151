var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"col-12 text-left"})],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":this.headers,"items":this.rows,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Tabla con listado completo de Iniciativas.")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.estrategia",fn:function(ref){
var item = ref.item;
return [(item.estrategia.length < 150 )?_c('div',[_vm._v(_vm._s(item.estrategia))]):_c('div',[_vm._v(_vm._s(item.estrategia.substring(0, 146)+ " ..."))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name.length < 150 )?_c('div',[_vm._v(_vm._s(item.name))]):_c('div',[_vm._v(_vm._s(item.name.substring(0, 146)+ " ..."))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.description.length < 150 )?_c('div',[_vm._v(_vm._s(item.description))]):_c('div',[_vm._v(_vm._s(item.description.substring(0, 146)+ " ..."))])]}},{key:"item.percentageProgress",fn:function(ref){
var item = ref.item;
return [(item.percentageProgress === null)?_c('div',[_vm._v("Anulada")]):_c('div',[_vm._v(_vm._s(item.percentageProgress)+"%")])]}},{key:"item.filename",fn:function(ref){
var item = ref.item;
return [(item.filename != null && item.filename.length > 0)?_c('div',[_vm._v("si")]):_c('div',[_vm._v("no")])]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2 success",attrs:{"small":""},on:{"click":function($event){return _vm.showProgress(item)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-eye")]),_vm._v(" Visualizar ")],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"800px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Reporte de avance final")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Progreso","placeholder":"Progreso","readonly":""},model:{value:(_vm.progress.percent),callback:function ($$v) {_vm.$set(_vm.progress, "percent", $$v)},expression:"progress.percent"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Observaciones","readonly":""},model:{value:(_vm.progress.observations),callback:function ($$v) {_vm.$set(_vm.progress, "observations", $$v)},expression:"progress.observations"}})],1)],1),(_vm.progress.filename != null && _vm.progress.filename.length > 0)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"v-card--link",on:{"click":function($event){return _vm.downloadFile()}}},[_c('img',{attrs:{"src":require("@/assets/pdf.png"),"alt":_vm.progress.filename,"width":"48","height":"48"}}),_vm._v(" "+_vm._s(_vm.progress.filename))])])],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cerrar")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }