<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col class="col-12 text-left"></v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
          dense
          :headers="this.headers"
          :items="this.rows"
          item-key="id"
          class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tabla con listado completo de Iniciativas.</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item.estrategia="{item}">
          <div v-if="item.estrategia.length < 150 ">{{item.estrategia}}</div>
          <div v-else>{{ item.estrategia.substring(0, 146)+ " ..." }}</div>
        </template>
        <template v-slot:item.name="{item}">
          <div v-if="item.name.length < 150 ">{{item.name}}</div>
          <div v-else>{{ item.name.substring(0, 146)+ " ..." }}</div>
        </template>
        <template v-slot:item.description="{item}">
          <div v-if="item.description.length < 150 ">{{item.description}}</div>
          <div v-else>{{ item.description.substring(0, 146)+ " ..." }}</div>
        </template>
        <template v-slot:item.percentageProgress="{item}">
          <div v-if="item.percentageProgress === null">Anulada</div>
          <div v-else>{{item.percentageProgress}}%</div>
        </template>
        <template v-slot:item.filename="{item}">
          <div v-if="item.filename != null && item.filename.length > 0">si</div>
          <div v-else>no</div>
        </template>
        <template v-slot:item.acciones="{item}">
          <v-btn small class="mr-2 success" @click="showProgress(item)">
            <v-icon small class="mr-1">mdi-eye</v-icon> Visualizar
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog" max-width="800px" persistent>
        <v-card>
          <v-card-title>
            <span class="text-h5">Reporte de avance final</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      v-model="progress.percent"
                      label="Progreso"
                      placeholder="Progreso"
                      readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                      label="Observaciones"
                      v-model="progress.observations"
                      readonly
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row v-if="progress.filename != null && progress.filename.length > 0">
                <v-col>
                  <div @click="downloadFile()" class="v-card--link">
                  <img src="@/assets/pdf.png" :alt="progress.filename" width="48" height="48">
                  {{progress.filename}}</div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close()">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
import {mapActions} from 'vuex'
import Swal from "sweetalert2";
export default {
  name: "InitiativesMaintainer",
  data() {
      return {
        loading: false,
        dialog: false,
        rows: [],
        progress: {
          id: 0,
          percent: "",
          observations: "",
          filename: "",
          mime: ""
        },
        progressDefault: {
          id: 0,
          percent: "",
          observations: "",
          filename: "",
          mime: ""
        },
        headers: [
          { text: 'Plan Tranversal', value: 'planTransversal.name', align: 'start', sortable: true },
          { text: 'Eje Estrategico', value: 'estrategia.ejeEstrategico.nombreEje' },
          { text: 'Area Misional', value: 'estrategia.areaMisional.nombreArea' },
          { text: 'Estrategia', value: 'estrategia.name', width: '22%' },
          { text: 'Nombre', value: 'name' },
          { text: 'Descripción', value: 'description',width: '22%' },
          {text: 'Fecha Inicio', value: 'startDate'},
          {text: 'Fecha Termino', value: 'endDate'},
          {text: 'Avance', value: 'percentageProgress'},
          {text: 'Archivo', value: 'filename'},
          { text: "Acciones", value: 'acciones'}
        ],
      }
  },
  methods: {
    ...mapActions('main', [
      'getAllFinishedInitiatives',
        'serviceInitiativeShowFile'
    ]),
    getAllItemsInitiatives(){
      this.getAllFinishedInitiatives().then(response => {
        this.rows = response.data.response;
      }).catch(e => {
        console.log(e);
      });
    },
    showProgress(item){
      this.progress.percent = (item.percentageProgress === null)?"Iniciativa Anulada": item.percentageProgress + " %";
      this.progress.observations = item.progressFeedback;
      this.progress.filename = item.filename;
      this.progress.mime = item.mime;
      this.progress.id = item.id;
      this.dialog = true;
    },

    close(){
      this.dialog = false;
      this.$nextTick(() => {
        this.progress = Object.assign({}, this.progressDefault);
      })
    },
    downloadFile() {
      this.serviceInitiativeShowFile(this.progress.id)
          .then(response => {
            this.download(response.data.response);
          })
          .catch(err => {
            console.log(err.response);
            Swal.fire({
              title: 'Obtener archivo evidencia iniciativa finalizada',
              text: err,
              confirmButtonColor: '#EA7600',
              confirmButtonText: 'Aceptar',
              icon: 'warning',
            });
          });
    },
    download(base64){
      const linkSource = `data:${this.progress.mime};base64,${base64}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = this.progress.filename;
      downloadLink.click();
    },
  },
  async created() {
    await this.getAllItemsInitiatives();
  },
}
</script>
<style scoped>

</style>