<template>
  <v-container fluid class="content">
    <h1><strong>Porcentaje de Avance de Iniciativas: {{ this.percentAvg }}%</strong></h1>
    <v-divider class="mt-2 mb-2"></v-divider>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col class="col-10 text-left">
            Reporte de Avances del listado de Iniciativas.
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="col-4">
            <v-select
                placeholder="Plan Transversal"
                :items="planesTransversalesFilter"
                :item-text="'name'"
                :item-value="'id'"
                icon="mdi-arrow-bottom-right-thin-circle-outline"
                label="Plan Transversal"
                @change = "id => fieldSearch(id, 0)"
            />
          </v-col>
          <v-col class="col-4">
            <v-select
                placeholder="Eje Estratégicos"
                :items="ejesEstrategicosFilter"
                :item-text="'nombreEje'"
                :item-value="'id'"
                icon="mdi-arrow-bottom-right-thin-circle-outline"
                label="Eje Estratégico"
                @change = "id => fieldSearch(id, 1)"
            />
          </v-col>
          <v-col class="col-4">
            <v-select
                placeholder="Area Misional"
                :items="areasMisionalesFilter"
                :item-text="'nombreArea'"
                :item-value="'id'"
                icon="mdi-arrow-bottom-right-thin-circle-outline"
                label="Area Misional"
                @change = "id => fieldSearch(id, 2)"
            />
          </v-col>
        </v-row>
        <v-data-table
            dense
            :headers="this.headers"
            :items="this.iniciativas"
            item-key="id"
            class="elevation-1"
            :search="search"
            :loading="loading">

          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Tabla con listado de iniciativas</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="800px" persistent>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Registrar Avance de Iniciativa</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <p> <span class="text-h5">{{iniciativa.name}}</span></p>
                      <v-row>
                        <v-col cols="12">
                          <v-select
                              class="mt-3 mb-4"
                              placeholder="Selecciona Porcentaje de Avance"
                              :items="percents"
                              item-text="text"
                              item-value="value"
                              icon="mdi-arrow-bottom-right-thin-circle-outline"
                              v-model="iniciativa.percentageProgress"/>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                              filled
                              label="Observaciones"
                              color="primary-color"
                              no-resize
                              name="input-7-5"
                              v-model="iniciativa.progressFeedback"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeDialog()">Cerrar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="updateAllProgress()">Modificar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.estrategia="{item}">
            <div v-if="item.estrategia.length < 150 ">{{item.estrategia}}</div>
            <div v-else>{{ item.estrategia.substring(0, 146)+ " ..." }}</div>
          </template>
          <template v-slot:item.name="{item}">
            <div v-if="item.name.length < 150 ">{{item.name}}</div>
            <div v-else>{{ item.name.substring(0, 146)+ " ..." }}</div>
          </template>
          <template v-slot:item.description="{item}">
            <div v-if="item.description.length < 150 ">{{item.description}}</div>
            <div v-else>{{ item.description.substring(0, 146)+ " ..." }}</div>
          </template>
          <template v-slot:item.acciones="{item}">
            <v-btn small class="mr-2 primary mb-2" @click="recordProgress(item)">
              <v-icon small class="mr-1">mdi-pencil</v-icon>Editar</v-btn>

            <v-btn small class="error" elevation="0" @click="uploadAndFinish(item)">
              <v-icon small class="mr-1">mdi-cancel</v-icon>
              Finalizar
            </v-btn>
          </template>
        </v-data-table>
        <v-dialog v-model="dialogFinished" max-width="800px" persistent>
          <v-card>
            <v-card-title>
              <span class="text-h5">Finalizar Iniciativa {{iniciativa.name}}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                        v-model="iniciativa.file"
                        label="Seleccione el archivo pdf para subir. Archivo no es obligatorio."
                        accept="application/pdf"
                        show-size
                        counter
                        chips
                        truncate-length="50"
                        :clearable="false"
                        :loading="loading">
                    </v-file-input>
                  </v-col>
                </v-row>

              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn color="blue darken-1" text @click="closeDialog()">Cerrar</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="preFinish()">Finalizar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
      <v-card-actions>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Swal from "sweetalert2";
export default {
  name: "InitiativesProgressReport",
  data() {
    return {
      headers: [
        { text: 'Plan Tranversal', value: 'planTransversal.name', align: "left", width: "15%"},
        { text: 'Eje Estrategico', value: 'estrategia.ejeEstrategico.nombreEje', align: "center", width: "9%" },
        { text: 'Area Misional', value: 'estrategia.areaMisional.nombreArea', align: "center", width: "9%" },
        { text: 'Estrategia', value: 'estrategia.name', align: "left", width: "15%" },
        { text: 'Nombre', value: 'name', align: "center", width: "15%" },
        { text: 'Descripción', value: 'description', align: "justify", width: "20%" },
        { text: 'Fecha Inicio', value: 'startDate', align: "justify", width: "8%"},
        { text: 'Fecha Termino', value: 'endDate', align: "justify", width: "7%"},
        { text: 'Procentaje de Avance', value: 'percentageProgress',width: "5%", align: "center" },
        { text: "Acciones", value: 'acciones', align: "justify"}
      ],
      iniciativas: [],
      search:"",
      loading: false,
      iniciativa: {
        id: 0,
        name: "",
        percentageProgress: 0,
        progressFeedback: "",
        file: {}
      },
      defaultIniciativa: {
        id: 0,
        name: "",
        percentageProgress: 0,
        progressFeedback: "",
        file: {}
        },
      percentAvg: 0,
      percents: [
        {value: 0, text: '0%'},
        {value: 10, text: '10%'},
        {value: 20, text: '20%'},
        {value: 30, text: '30%'},
        {value: 40, text: '40%'},
        {value: 50, text: '50%'},
        {value: 60, text: '60%'},
        {value: 70, text: '70%'},
        {value: 80, text: '80%'},
        {value: 90, text: '90%'},
        {value: 100, text: '100%'},
        {value: null, text: 'Anular Iniciativa'},
      ],
      dialog: false,
      dialogFinished: false,
      planesTransversalesFilter: [],
      ejesEstrategicosFilter:[],
      areasMisionalesFilter:[],

    }
  },
  methods: {
    ...mapActions('main', [
      'getAllNotFinishedInitiatives',
        'updateProgress',
        'finishIniciativa',
        'getAllEjeEstrategico',
        'getAllPlanesTransversales',
        'getAllAreaMisional'

    ]),
    uploadAndFinish(item){
      this.iniciativa.id = item.id;
      this.iniciativa.name = item.name;
      this.dialogFinished=true;
    },
    fieldSearch(id, type) {
      switch (type) {
        case 0:
          this.fieldSearchPlanTransversal(id);
          break;
        case 1:
          this.fieldSearchEjeEstrategico(id);
          break;
        case 2:
          this.fieldSearchAreaMisional(id);
          break;
      }
    },
    fieldSearchPlanTransversal(id){
      if(id === 0) {
        this.search = "";
      } else {
        let res = this.planesTransversalesFilter.filter((element) => {
          return element.id === id
        });
        this.search = res[0].name;
      }
    },
    fieldSearchEjeEstrategico(id){
      if(id === 0) {
        this.search = "";
      }else{
        let res = this.ejesEstrategicosFilter.filter((element) => {
          return element.id === id
        });
        this.search = res[0].nombreEje;
      }
    },
    fieldSearchAreaMisional(id){
      if(id === 0) {
        this.search = "";
      }
      else {
        let res = this.areasMisionalesFilter.filter((element) => {
          return element.id === id
        });
        this.search = res[0].nombreArea;
      }
    },
    getAllItemsPlanesTransversalesFilter() {
      this.getAllPlanesTransversales().then(response => {
        this.planesTransversalesFilter.push({id: 0, name: "Todos"});
        response.data.response.forEach((item) => {
          this.planesTransversalesFilter.push(item);
        });
      }).catch(e => {
        console.log(e);
      });
    },

    getAllItemsEjeEstrategicosFilter() {
      this.getAllEjeEstrategico().then(response => {
        this.ejesEstrategicosFilter.push({id: 0, nombreEje: "Todos"});
        response.data.response.forEach((item) => {
          this.ejesEstrategicosFilter.push(item);
        });
      }).catch(e => {
        console.log(e);
      });
    },

    getAllItemsAreasMisionalesFilter() {
      this.getAllAreaMisional().then(response => {
        this.areasMisionalesFilter.push({id: 0, nombreArea: "Todos"});
        response.data.response.forEach((item) => {
          this.areasMisionalesFilter.push(item);
        });
      }).catch(e => {
        console.log(e);
      });
    },
    recordProgress(item){
      this.iniciativa.id = item.id;
      this.iniciativa.name = item.name;
      this.iniciativa.percentageProgress = item.percentageProgress;
      this.iniciativa.progressFeedback = item.progressFeedback;
      this.dialog = true;
    },
    closeDialog(){
      this.dialog = false;
      this.dialogFinished=false;
      this.$nextTick(() => {
        this.iniciativa = Object.assign({}, this.defaultIniciativa);
      })
    },
    getAllItemsNotFinishedInitiatives(){
      this.getAllNotFinishedInitiatives().then(response => {
        this.iniciativas = [];
        response.data.response.forEach((item) => {
          item.disabled = false;
          item.initialProgressFeedback = "";
          item.editProgressFeedback = false;
          this.iniciativas.push(item);
        });
        this.calculateAverage();
      }).catch(e => {
        console.log(e);
      });
    },
    // eslint-disable-next-line no-unused-vars
    updateAllProgress(){
      this.updateProgress(this.iniciativa).then(async response => {
        console.log(response);
        Swal.fire({
          title: 'Actualizar avance iniciativa',
          text: 'Se ha actualizado el avance de la iniciativa',
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        });
        await this.getAllItemsNotFinishedInitiatives();
        this.closeDialog();
      }).catch(err => {
        console.log(err);
        Swal.fire({
          title: 'Actualizar avance iniciativa',
          text: err,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
      });
    },
    calculateAverage() {
      let total = 0, length = this.iniciativas.length;
      let elements = this.iniciativas.length;
      for (var i = 0; i < length; i++) {
        if(this.iniciativas[i].percentageProgress != null)
          total += parseFloat(this.iniciativas[i].percentageProgress);
        else
          elements -=1
      }
      if(total === 0 && elements === 0)
        this.percentAvg = 0;
      else
        this.percentAvg = Math.floor(total / elements);
    },
    preFinish(){
      Swal.fire({
        title: 'Importante:',
        text: 'Una vez finalizada, no podrá volver a editar esta iniciativa. ¿Desea continuar ..?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Finalizar',
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.finish()
        } else if (result.isDenied) {
          this.closeDialog();
          Swal.fire('Iniciativa no será finalizada.', '', 'info')
        }
      })
    },
    // eslint-disable-next-line no-unused-vars
    finish(){
      this.finishIniciativa(this.iniciativa)
          // eslint-disable-next-line no-unused-vars
          .then(response=>{
            console.log(response.data.response);
            Swal.fire({
              title: 'Finalizar iniciativa',
              text: 'Se ha finalizado la inciativa' + this.iniciativa.name,
              confirmButtonColor: '#EA7600',
              confirmButtonText: 'Aceptar',
              icon: 'success',
            }).then(function(){
              window.location.reload();
            });
          }).catch(err => {console.log(err);
        Swal.fire({
          title: 'Finalizar iniciativa',
          text: err.response.data.message,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
      });
     }
  },
  async created() {
    await this.getAllItemsPlanesTransversalesFilter();
    await this.getAllItemsEjeEstrategicosFilter();
    await this.getAllItemsAreasMisionalesFilter();
    await this.getAllItemsNotFinishedInitiatives();
  },
  computed: {
    ...mapGetters({
      info: ['authentication/info'],
      fullTokenDecoded: ['authentication/fullTokenDecoded']
    })
  }
}
</script>

<style lang="css" scoped>

</style>